import React from "react";

const TermsAndConditions = ({ darkTheme }) => {
  return (
    <div
      id="terms-policy"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Terms &amp; Policy
            </h5>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <p>
              The website mandruzzato.dev uses cookies, also from third parties,
              to ensure you get the best experience on our website
              Technical cookies and Google Analytics cookies are used.
            </p>
            <p>
              If the user clicks I AGREE on the disclaimer cookies are
              used. If the user does not click I AGREE cookies are not
              used.</p> <p>To make the disclaimer disappears it is necessary to click I
              AGREE.</p> <p>The website mandruzzato.dev complies with EU Cookie Law, tested
              with <a href="https://www.cookiemetrix.com" target="_blank" rel="noreferrer">COOKIE METRIX</a>
            </p>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
