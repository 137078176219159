import React from "react";

const Services = ({ classicHeader, darkTheme }) => {
  // services details
  const services = [
    
    {
      name: "Project Management",
      desc: "Planning, organizing, executing, and controlling resources and activities to achieve specific objectives within a defined timeframe and budget.",
      icon: "fas fa-diagram-project",
    },
    {
      name: "Team Leading",
      desc: "I have a wealth of experience in leading development teams, providing guidance, and fostering collaboration among team members, making me a proficient developer team leader.",
      icon: "fas fa-people-group",
    },
    {
      name: "Analysis",
      desc: "Understand the needs of the client or organization, design a software solutions and ensure that the software functions correctly and meets the user requirements.",
      icon: "fas fa-magnifying-glass-chart",
    },
    {
      name: "Coding",
      desc: "I am a highly skilled programmer with a proven ability to design, develop, and optimize software solutions that not only meet project requirements but also exhibit a deep understanding of coding best practices, efficiency, and maintainability",
      icon: "fas fa-code",
    },
    {
      name: "Microservices",
      desc: "Design, develop a software architecture approach into small, independent, and loosely coupled services",
      icon: "fas fa-cloud",
    },  
    {
      name: "Coaching",
      desc: "I specialize in guiding and mentoring developers to help them enhance their skills and reach their full potential, making me an effective developer coach.",
      icon: "fas fa-person-chalkboard",
    },
  
  ];

  return (
    <section
      id="services"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
            }
          >
            Services
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            What I Do?
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-box style-3 mb-5">
                      <div
                        className={
                          "featured-box-icon text-primary  shadow-sm rounded " +
                          (darkTheme ? "bg-dark-1" : "bg-white")
                        }
                      >
                        <i className={service.icon} />
                      </div>
                      <h3 className={darkTheme ? "text-white" : ""}>
                        {service.name}
                      </h3>
                      <p
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        {service.desc}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Services;
