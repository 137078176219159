import React from "react";

import Slider from "react-slick";

const Testimonials = ({ classicHeader, darkTheme }) => {
  const reviews = [
    {
      name: "Paolo Piazzi",
      position: "Team Leader from T3LAB",
      src: "https://media.licdn.com/dms/image/C5603AQFR14YOI2bS0g/profile-displayphoto-shrink_400_400/0/1517555418149?e=1704931200&v=beta&t=PT_baniu__vSGqq9TzFTmCgnfGvFO_Mc02mxViC2u84",
      desc: "Stefano joined our engineering team at T3LAB to add a brand new web app to an existing project.\n He had great ideas, good experience despite being a junior software engineer and was always supportive of our colleagues. He managed to keep his project under control even when his own workload was growing and the customer wanted to review the progress.\n He’s a top-notch web engineer, with also experience of desktop based software, who’d made a great addition to any product development team. Finally, other team members were enthusiastic to have Stefano in the team as a collegue but also as a friend.",
      rating: 5,
    },
    {
      name: "Massimilano Rango",
      position: "Collegue from InfoCert",
      src: "https://media.licdn.com/dms/image/C5603AQF3Z9uqr63oxg/profile-displayphoto-shrink_400_400/0/1516447290417?e=1704931200&v=beta&t=u0j5N8VJgrzYTxDnXkJp42QvIp0GYZgECQRLxe3GNFo",
      desc: "I had the opportunity and pleasure of working with Stefano for a year and a half during my experience at InfoCert. Certainly a trustworthy person, who doesn't back down when faced with the first difficulty. A glue element within the group, he always manages to contribute actively by bringing his own ideas to the table and making his opinion count. Having Stefano in your work team is certainly an advantage from both a technical and human point of view.",
      rating: 5,
    }
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <section
      id="testimonial"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            References
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Collegue Speak
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}

        <Slider {...settings}>
          {reviews.length > 0 &&
            reviews.map((value, index) => (
              <div className="item" key={index}>
                <div
                  className={
                    " rounded p-5 " + (darkTheme ? "bg-dark" : "bg-light")
                  }
                >
                  <div className="d-flex align-items-center mt-auto mb-4">
                    <img
                      className="img-fluid img-w-67 rounded-circle border d-inline-block w-auto"
                      src={value.src}
                      alt=""
                    />
                    <p className="ms-3 mb-0">
                      <strong
                        className={
                          "d-block fw-600 " +
                          (darkTheme ? "text-white" : "text-dark")
                        }
                      >
                        {value.name}
                      </strong>
                      <span className="text-muted fw-500">
                        {" "}
                        {value.position}{" "}
                      </span>
                    </p>
                  </div>
                  <p
                    className={
                      " fw-500 mb-4 " + (darkTheme ? "text-white" : "text-dark")
                    }
                  >
                    “{value.desc}”
                  </p>
                  {/* <span className="text-2">
                    {[...Array(value.rating)].map((value, i) => (
                      <i className="fas fa-star text-warning" key={i} />
                    ))}
                  </span> */}
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
