import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "2011 - 2013",
      title: "Master Science",
      place: "Università degli Studi di Padova",
      desc: "Network Security Web Application ERASMUS IP 2012 The title of thesis is: \"DriveFarm: a client for real-time synchronization in Cloud environment\". 108/110 ",
    },
    {
      yearRange: "2011 - 2012",
      title: "Erasmus LP, Computer Science",
      place: "University of Aberdeen",
      desc: "I attended one semester at University of Aberdeen. avg score 18/20",
    },
    {
      yearRange: "2007 - 2011",
      title: "Bachelor Degree",
      place: "Università degli Studi di Padova",
      desc: "The tytle of thesis is: \"Study and development of a communication interface with the platform of the telephone company to deliver services via SMS\".",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2020 - 2023",
      title: "Team Leader and Senior Software Engineer",
      place: "Ennova Research, Venezia, Italy",
      desc: "Team leader of development teams in multiple projects. My principal role is to coordinate the development activity, help the professional growth of team members, improving development and teamwork techniques. Most of the projects which I'm working on it are based of MEAN Stack",
    },
    {
      yearRange: "2014 - 2020",
      title: "Scrum Master and Software Engineer",
      place: "InfoCert S.p.A. Padova, Italy",
      desc: "Development and improvement of desktop application for Cloud Storage using Python and QT framework for UI. Specialization in front-end web applications with AngularJS framework. Introduce in a company Ci/CD using Git, Gitlab and Jenkins.",
    },
    {
      yearRange: "2013 - 2014",
      title: "Researcher",
      place: "T3LAB, Bologna Italy",
      desc: "Development of research products of Augmented Reality.",
    },
  ];

  const personalSkills = [
    {
      name: "Team Leading",
      percent: 100,
    },
    {
      name: "Project Management",
      percent: 100,
    },
    {
      name: "Reliability",
      percent: 100,
    },
    {
      name: "Commitment",
      percent: 100,
    }
  ];

  const codingSkills = [
    {
      name: "JavaScript/Typescript",
      percent: 90,
    },
    {
      name: "Java",
      percent: 80,
    },
    {
      name: "Python",
      percent: 60,
    },
    {
      name: "C++",
      percent: 60,
    },
    {
      name: "Nest.JS",
      percent: 80,
    },
    {
      name: "Angular",
      percent: 70,
    },
    {
      name: "React",
      percent: 80,
    },
    {
      name: "Spring",
      percent: 70,
    },
  ]

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
          {/* My Experience */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          Personal Skills
        </h2>
        <div className="row gx-5">
          {personalSkills.length > 0 &&
            personalSkills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  {/* <span className="float-end">{skill.percent}%</span> */}
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          Coding Skills
        </h2>
        <div className="row gx-5">
          {codingSkills.length > 0 &&
            codingSkills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  {/* <span className="float-end">{skill.percent}%</span> */}
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
